import React, { FC } from 'react';
import NextImage from 'next/image';
import { ImageProps } from 'next/dist/client/image';

type Props = Partial<ImageProps>;

const Image: FC<Props> = (props) => {
  const imageProps = { ...props } as ImageProps;
  const { alt, ...rest } = imageProps;
  return <NextImage alt={alt || ''} {...rest} />;
};

export default Image;
